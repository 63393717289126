import React, {useState} from 'react'
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc, Timestamp } from 'firebase/firestore/lite';
import {  message, Space } from 'antd';

function App() {
  const [messageApi, contextHolder] = message.useMessage();
  const [sending, setSending] = useState(false);
  const [state, setState] = useState({
    email:'',
    name: '',
    subject:'',
    message: '',
  })

  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Your message has been sent!',
      style: {
        marginTop: '20vh',
      },
    });
  };

  const warning = (field) => {
    messageApi.open({
      type: 'warning',
      content: `Please put a correct value into "${field}" field`,
      style: {
        marginTop: '20vh',
      },
    });
  }

  const validateField = (field, fname, maxLength) => {
    if (field.length === 0 || field.length > maxLength) { 
      warning(fname);
      return false;
    }

    return true;
  }
  
  const setSubject = (val) => {
    //console.log('setting subject dynamically')
    setState({
      ...state,
      subject: `Subject: ${val}`
    });

    document.getElementById("contact").scrollIntoView();
  }

  window.setSubject = setSubject;

  const sender = async (msg) => {
    const { name, email, subject, message } = state;
    if (!validateField(name, 'name', 50) || 
        !validateField(email, 'email', 50) ||
        !validateField(subject, 'subject', 50) ||
        !validateField(message, 'message', 2000)) { 
      return;
    }

    setSending(true);

    try {
      const firebaseConfig = {
        apiKey: "AIzaSyAGdcLGhH55i7ABlgSDy2BKAo9GEOW_q2g",
        authDomain: "wowdeveloptech.firebaseapp.com",
        databaseURL: "https://wowdeveloptech.firebaseio.com",
        projectId: "wowdeveloptech",
        storageBucket: "wowdeveloptech.appspot.com",
        messagingSenderId: "239076782804",
        appId: "1:239076782804:web:9a92477a0f8a3f0b530141"
      };
      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);
  
      // Add a new document with a generated id.
      const docRef = await addDoc(collection(db, "Messages"), {
        ...state,
        createdate: Timestamp.now()
      });
      //console.log("Document written with ID: ", docRef.id);
  
      if (docRef !== null) {
        success();
      }
    } catch {
      // todo: send error
    } finally {
      setSending(false);
    }
  }
  
  const changeHandler = (event) => {
    setState({...state, [event.target.name] : event.target.value})
  }

  return (
    <footer className="footer">
            <div className="footer-seperator" id="contact">
                <div className="content-lg container">
                    <div className="row">
                        <div className="col-sm-2 sm-margin-b-50">
                            <ul className="list-unstyled footer-list">
                                <li className="footer-list-item"><a className="footer-list-link" href="#services">Services</a></li>
                                <li className="footer-list-item"><a className="footer-list-link" href="#career">Career</a></li>
                                <li className="footer-list-item"><a className="footer-list-link" href="#about">About</a></li>
                               {/* <li className="footer-list-item"><a className="footer-list-link" href="#team">Team</a></li>*/}
                            </ul>
                        </div>
                        <div className="col-sm-4 sm-margin-b-30">
                            <ul className="list-unstyled footer-list">
                                <li className="footer-list-item"><a className="footer-list-link" href="https://www.linkedin.com/company/wowdevelop/">Linkedin</a></li>
                            </ul>
                        </div>
                        {contextHolder}
                        <Space></Space>
                        <div className="col-sm-5 sm-margin-b-30">
                            <h2 className="color-white">Contact us</h2>
                            <input type="text" name='name' maxLength={40} className="form-control footer-input margin-b-20" placeholder="Your name" required value={state.name} onChange={changeHandler} />
                            <input type="email" name='email' maxLength={50} className="form-control footer-input margin-b-20" placeholder="Email" required value={state.email} onChange={changeHandler} />
                            <input id="emailSbj" name='subject' maxLength={50} type="text" className="form-control footer-input margin-b-20" placeholder="The subject" required value={state.subject} onChange={changeHandler} />
                            <textarea name='message' maxLength={2000} className="form-control footer-input margin-b-30" rows="6" placeholder="Message" required value={state.message} onChange={changeHandler}></textarea>
                            <button disabled={sending} type="submit" className="btn-theme btn-theme-sm btn-main text-uppercase" onClick={sender}>Submit</button>
                        </div>
                        
                    </div>
                </div>
            </div>

            <div className="content container">
                <div className="row">
                    <div className="col-xs-6">
                        <img className="footer-logo" src="img/logo.png" alt="Asentus Logo" />
                    </div>
                    <div className="col-xs-6 text-right">
                        <p className="margin-b-0">
                            <span className="color-base fweight-700" >Digital</span>
                            <span>Worldwide</span>
                            <span className="color-base fweight-700">Outstanding</span></p>
                    </div>
                </div>
            </div>
        </footer>
  );
}

export default App;
